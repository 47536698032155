<template>
  <transition name="fade" mode="out-in">
    <template v-if="!type">
      <main class="other-auth" key="none">
        <div class="text">
          <i class="fa-solid fa-question fa-4x"></i>
          <div class="title">
            Forgot password or username?
          </div>
          <div class="subtitle">
            Don't worry! It happens. Please select what you have forgotten so we can continue to help you!
          </div>
        </div>
        <div class="continue">
          <router-link to="?what=username">Username</router-link>
          <router-link to="?what=password">Password</router-link>
        </div>
      </main>
    </template>
    <template v-else-if="type === 'username'">
      <main class="other-auth" key="username">
        <div class="text">
          <i class="fa-solid fa-user fa-4x"></i>
          <div class="title">
            Let's get you remember your username!
          </div>
          <div class="subtitle">
            We will send you an email with your username!
          </div>
        </div>
        <div class="continue">
          <div class="input">
            <i class="fa-solid fa-envelope fa-lg"></i>
            <input type="email" placeholder="Your email" v-model="email">
          </div>
          <a @click="usernameRequest" v-if="!usernameLoading">  Get my username </a>
          <a v-else>
            <i class="fa-solid fa-circle-notch fa-spin"></i>
          </a>
        </div>
      </main>
    </template>
    <template v-else-if="type === 'password'">
      <main class="other-auth" key="password">
        <div class="text">
          <i class="fa-solid fa-lock fa-4x"></i>
          <div class="title">
            Let's reset your password!
          </div>
          <div class="subtitle">
            We will send you an email with a link to reset your password!
          </div>
        </div>
        <div class="continue">
          <div class="input">
            <i class="fa-solid fa-envelope fa-lg"></i>
            <input type="email" placeholder="Your email" v-model="email">
          </div>
          <a @click="passwordReset" v-if="!passwordLoading">Reset password</a>
          <a v-else>
            <i class="fa-solid fa-circle-notch fa-spin"></i>
          </a>
        </div>
      </main>
    </template>
  </transition>
</template>

<script>
import axios from "axios"
export default {
  data: () => ({
    email: "",
    passwordLoading: false,
    usernameLoading: false,
  }),
  computed: {
    type() {
      return this.$route.query.what;
    }
  },
  methods: {
    usernameRequest() {
      if (this.usernameLoading) return;
      if (!this.email) {
        this.$notify({
          type: "error",
          text: "Please enter your email address!",
        });
        return;
      }
      this.usernameLoading = true;
      axios.get(this.$api("/user/recoverUsername"), {
          params: {
            email: this.email
          }
        },
        {
          headers: {
            Authorization: "Bearer " + this.$store.state.token
          }
        }).then(res => {
        res;
        this.$notify({
          type: "success",
          text: "We have sent you an email with your username!",
        })
        this.$router.push("/login");
      }).catch(err => {
        this.$notify({
          type: "error",
          text: err.response.data.message,
        })
        this.usernameLoading = false;
      });
    },
    passwordReset() {
      if (!this.email) {
        this.$notify({
          type: "error",
          text: "Please enter your email address!",
        });
        return;
      }
      this.passwordLoading = true;
      axios.get(this.$api("/user/recoverPassword"), {
          params: {
            email: this.email
          },
        }, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token
          }
        }).then(res => {
        res;
        this.$notify({
          type: "success",
          text: "We have sent you an email with a link to reset your password!"
        });
        this.$router.push("/login");
      }).catch(err => {
        alert(err);
      });
    }
  }
}
</script>

<style>

</style>